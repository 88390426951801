











































































import { InputSetups } from '@/mixins/input-setups'
import ModuleBindings from '@/includes/logic/Modules/mixins/ModuleBindings'
import EditMediaHandlerModuleBuilder
  from '../../../../includes/logic/Modules/models/modules/EditMediaHandlerModule/EditMediaHandlerModuleBuilder'
import ModuleConfigSettingsWrapper from '@/components/Modules/components/ModuleConfigSettingsWrapper.vue'
import ModuleConfigSettings from '@/components/Modules/components/ModuleConfigSettings.vue'
import Placeholders from "@/mixins/placeholders/placeholders";

import SwitchInput from 'piramis-base-components/src/components/Pi/fields/SwitchInput/SwitchInput.vue'
import NumberInput from 'piramis-base-components/src/components/Pi/fields/NumberInput/NumberInput.vue'
import TimeUnitInput from 'piramis-base-components/src/components/Pi/fields/TimeUnitInput/TimeUnitInput.vue'
import MultiMessageEditorWithMediaInput from 'piramis-base-components/src/components/Pi/fields/NewMultiMessageEditorWithMediaInput/MultiMessageEditorWithMediaInput.vue'
import { MediaEditorMode } from 'piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/types'
import { StepUnit } from 'piramis-base-components/src/components/PeriodSimplifier/types'

import { Component, Mixins, VModel } from 'vue-property-decorator'

@Component({
  components: {
    SwitchInput,
    TimeUnitInput,
    NumberInput,
    MultiMessageEditorWithMediaInput,
    ModuleConfigSettingsWrapper,
    ModuleConfigSettings,
  },
  data() {
    return {
      MediaEditorMode,
      StepUnit
    }
  }
})
export default class EditMediaHandlerModuleView extends Mixins(ModuleBindings, InputSetups, Placeholders) {
  @VModel() module!: EditMediaHandlerModuleBuilder
}
